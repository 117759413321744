/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { JSXElementConstructor, Key, ReactElement, useRef } from "react";
import "./App.css";
import Monitor from "./pages/Monitor";
import { useState, useEffect } from "react";
import NameModal from "./pages/NameModal";
import CommentsModal from "./pages/CommentsModal";
import ForwardModal from "./pages/ForwardModal";
import RouterDetailsModal from "./pages/RouterDetailsModal";
import ContactDetailsModal from "./pages/ContactDetailsModal";
import TopologyModal from "./pages/TopologyModal";
import FinanceModal from "./pages/FinanceModal";
import IpAddress from "ip-address";
import Settings from "./pages/Settings";
import Users from "./pages/UserManagement";
import Alerts from "./pages/Alerts";
import Notes from "./pages/Notes";
import {
  OperatorActionProps,
  SuperUserOperatorActionProps,
  SecondaryActionProps,
  we_are_superuser,
} from "./utils";
import LatenciesModal from "./pages/LatenciesModal";
import { DeviceEntryReturn, Identity, SMSMessage, Status } from "./types";
import MultipleRoutersModal from "./pages/MultipleRoutersModal";
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import FilterModal from "./pages/FilterModal";
import RouterStatusesModal from "./pages/RouterStatusesModal";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "layouts/authentication/sign-in/basic";
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav } from "context";
import SidenavLanding from "examples/Sidenav/LandingPageSideNav";
import routes_landing, { routes_network } from "deviceroutes";

import brandWhite from "assets/images/logo-white.png";
import brandDark from "assets/images/logo-black.png";
import DashboardLanding from "layouts/dashboards/landing";
import OverviewModal from "pages/OverviewModal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SidenavNetwork from "examples/Sidenav/NetworkSideNav";
import SignUp from "layouts/authentication/sign-up";
import Verify from "layouts/authentication/sign-up/smsauth";
import Confirm from "layouts/authentication/sign-up/confirmation";
import Customers from "pages/CustomerManagement";
import MDSnackbar from "components/MDSnackbar";
import SearchMonitor from "pages/SearchMonitor";

// 15 seconds
const UPDATE_TIME = 15000;
// 5 minutes
const FIVE_MINUTES = 300000;
const BACKEND_PORT = 8081;

export const RUN_MODE = process.env.REACT_APP_RUN_MODE;
export const SERVER_URL =
  (RUN_MODE === "test" ? "http://" : "https://") +
  window.location.hostname +
  ":" +
  BACKEND_PORT +
  "/";

function App() {
  document.title = "Operator Tools";

  const abortController = new AbortController();
  const signal = abortController.signal;
  // used for large requests like get networks that will take a while
  const timeoutId = setTimeout(() => {
    abortController.abort();
  }, 50000); // Set timeout to 5 minutes

  // Page state is here instead of just in the url to enable us to change
  // pages without a (long) request load time. Making the transition from
  // monitor to settings or notes instant. Because this is state changing
  // it will trigger a re-render whereas changing the window location will
  // not immediately cause a re-render
  const [page, setPage] = useState<null | string>(null);

  const path = window.location.search;
  const parts = path.split("/");
  let operator_address = "";
  if (parts[0]) {
    operator_address = parts[0].replace("?", "");
  }
  let local_page = "";
  if (parts[1] && page == null) {
    local_page = parts[1].replace("?", "");
  } else if (page) {
    local_page = page;
  }
  const [loggedIn, setLoggedIn] = useState<boolean>(true);
  const [lastLoginFailed, setLastLoginFailed] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  // data about the network, including routers and organizer settings we get from the server
  // todo create type
  const [networkdata, setNetworkData] = useState<any>(null);
  const [networksList, setNetworksList] = useState<any>(null);

  // Used to filter router statuses
  const [routerStatus, setRouterStatus] = useState<Status[]>([]);
  const [flaggedRouterStatus, setFlaggedRouterStatus] = useState<boolean[]>([]);
  // prevents filtering boolean array reset
  const firstTime = useRef(0);
  // counts for slow interval loop time
  const slow_interval_loops = useRef(0);

  // used to hold the router status of a single router and the router statuses modal
  const [routerStatuses, setRouterStatuses] = useState<any>([]);
  const [routerName, setRouterName] = useState<string>("");

  // the top level summary for the unattached routers group, which looks like just
  // another network on the networks list but actually uses a totally different set
  // of endpoints in order to avoid strange edge cases on the backend
  const [unattachedRouterSummary, setUnattachedRouterSummary] =
    useState<any>(null);
  const [nameModal, setNameModal] = useState<boolean>(false);
  const [overviewModal, setOverviewModal] = useState<boolean>(false);
  const [commentsModal, setCommentsModal] = useState<boolean>(false);
  const [forwardModal, setForwardModal] = useState<boolean>(false);
  const [contactDetailsModal, setContactDetailsModal] =
    useState<boolean>(false);
  const [routerDetailsModal, setRouterDetailsModal] = useState<boolean>(false);
  const [multipleRoutersModal, setMultipleRoutersModal] =
    useState<boolean>(false);
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const [topologyModal, setTopologyModal] = useState<boolean>(false);
  const [financeModal, setFinanceModal] = useState<boolean>(false);
  const [networkLatencyModal, setNetworkLatencyModal] =
    useState<boolean>(false);
  const [routerStatusesModal, setRouterStatusesModal] =
    useState<boolean>(false);
  const [deviceToEdit, setDeviceToEdit] = useState<any>(null);
  const [wifiActionsModal, setWifiActionsModal] = useState<boolean>(false);
  const [customerInfoModal, setCustomerInfoModal] = useState<boolean>(false);
  const [customerPage, setCustomerPage] = useState<boolean>(false);
  const [networkToEdit, setNetworkToEdit] = useState<any>(null);
  const [toggledNumberOfDays, setToggledDays] = useState<number>(-1);
  // our user permissions from the server, used to identify what we might
  // or might not have permission to do
  const [permissions, setPermissions] = useState<any>(null);
  // the list of users that the backend will allow us to see, this includes
  // any user that has overlapping permissions to us, so if we have any level
  // of access to network A we will see everyone who has any level of access
  // to network A but not their permissions on any other network
  const [usersList, setUsersList] = useState<any>(null);
  // the alerts list for the current network
  const [alerts, setAlerts] = useState<any>(null);
  // populated by and only used by the finances modal
  const [userFinances, setUserFinances] = useState<any>(null);
  // populated and used by latencies modal
  const [userLatencies, setUserLatencies] = useState<any>(null);
  // populated and used by latencies modal, this stores the compressed data points to
  // graph a device's bandwidth usage over the requested time period
  const [userBandwidthUsage, setUserBandwidthUsage] = useState<any>(null);
  // populated and used in the all networks page for average network bandwidth usage
  const [networkBandwidthUsage, setNetworkBandwidthUsage] = useState<any>(null);
  // populated and used by the monitor view to sort the all devices in the monitor page
  const deviceSortString = useRef<string>("Default");
  // used by the overview page to display long term operator fee earnings
  const [operatorFinances, setOperatorFinances] = useState<any>(null);
  // used by the overview page to show history of active user counts on a graph
  const [networkUserActivity, setNetworkUserActivity] = useState<any>(null);
  // used to hold list of available users to search from for the Router User Management modal
  const [customerList, setCustomerList] = useState<any>([]);
  // holds the single customer entry corresponding with a device
  const [customerToEdit, setCustomerToEdit] = useState<any>(null);
  // used to hold list of devices when selected via the checkboxes on monitor page
  const [checkedList, setCheckedList] = useState<Array<DeviceEntryReturn>>([]);
  const [epcList, setEpcList] = useState<any>(null);
  // for the submit notification message popups
  const [popupText, setPopupText] = useState<string>("");
  const [errorSB, setErrorSB] = useState<boolean>(false);
  const closeErrorSB = () => setErrorSB(false);
  const openErrorSB = () => setErrorSB(true);
  const [successSB, setSuccessSB] = useState<boolean>(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={popupText}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="checkbox"
      title="Success"
      content={popupText}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  // Added with the frontend update
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    sidenavColor,
    direction,
    layout,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const toggleSetWifiActionsModal = () => {
    setWifiActionsModal(!wifiActionsModal);
  };
  const toggleRouterStatusesModal = () =>
    setRouterStatusesModal(!routerStatusesModal);
  const toggleFiltermodal = () => setFilterModal(!filterModal);
  const toggleNameModal = () => setNameModal(!nameModal);
  const toggleOverviewModal = () => setOverviewModal(!overviewModal);
  const toggleCommentsModal = () => setCommentsModal(!commentsModal);
  const toggleForwardModal = () => setForwardModal(!forwardModal);
  const toggleRouterDetailsModal = () =>
    setRouterDetailsModal(!routerDetailsModal);
  const toggleMultipleRoutersModal = () =>
    setMultipleRoutersModal(!multipleRoutersModal);
  const toggleTopologyModal = () => setTopologyModal(!topologyModal);
  const toggleContactDetailsModal = () =>
    setContactDetailsModal(!contactDetailsModal);
  const toggleFinanceModal = () => setFinanceModal(!financeModal);
  const toggleNetworkLatencyModal = () =>
    setNetworkLatencyModal(!networkLatencyModal);
  const toggleCustomerInfoModal = () =>
    setCustomerInfoModal(!customerInfoModal);
  const toggleCustomerPage = () => setCustomerPage(!customerPage);
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return (
            <Route
              path={route.route}
              element={route.component}
              key={route.key}
            />
          );
        }

        return null;
      }
    );

  function triggerRouterStatusesModal(r_statuses: any[], name: string) {
    setRouterStatuses(r_statuses);
    setRouterName(name);
    setRouterStatusesModal(true);
  }
  function triggerFilterModal() {
    setFilterModal(true);
  }
  function triggerMonitorSort(sort_string: string) {
    deviceSortString.current = sort_string;
    getNetwork();
  }
  function triggerNetworkNameModal(network_to_edit: any) {
    setNameModal(true);
    setNetworkToEdit(network_to_edit);
  }
  function triggerCommentsModal(device_to_edit: any) {
    setCommentsModal(true);
    setDeviceToEdit(device_to_edit);
  }
  function triggerForwardModal(device_to_edit: any) {
    setForwardModal(true);
    setDeviceToEdit(device_to_edit);
  }
  function triggerRouterDetailsModal(device_to_edit: any) {
    setRouterDetailsModal(true);
    setDeviceToEdit(device_to_edit);
  }
  function triggerMultipleRoutersModal(devices_to_edit: any) {
    setMultipleRoutersModal(true);
    setCheckedList(devices_to_edit);
  }
  function triggerTopologyModal(device_to_edit: any) {
    setTopologyModal(true);
    setDeviceToEdit(device_to_edit);
  }
  async function triggerContactDetailsModal(device_to_edit: any) {
    await get_customer(device_to_edit);
    setContactDetailsModal(true);
    setDeviceToEdit(device_to_edit);
  }
  async function triggerOverviewModal(network_to_edit: any) {
    operator_address = network_to_edit.address;
    await get_network_usage(network_to_edit.address);
    await getNetworkUserActivity(network_to_edit.address);
    await getNetwork();
    await getOperatorFinances();
    setOverviewModal(true);
    setNetworkToEdit(network_to_edit);
  }
  async function triggerFinanceDetailsModal(device_to_edit: any) {
    // we get user finances here because the cost of making this
    // request is too high to do for everyone
    await get_user_finances(device_to_edit);
    setFinanceModal(true);
    setDeviceToEdit(device_to_edit);
  }
  async function triggerNetworkLatenciesModal(
    device_to_edit: any,
    toggled_number_of_days: any
  ) {
    if (
      !networkdata.fiber_mode ||
      (networkdata.fiber_mode && networkdata.fiber_mode === false)
    ) {
      // only compute latencies for non-fiber networks
      await get_user_latencies(device_to_edit, toggled_number_of_days);
    } else {
      setUserLatencies(null);
    }
    await get_device_usage(device_to_edit, toggled_number_of_days);
    setNetworkLatencyModal(true);
    setDeviceToEdit(device_to_edit);
  }
  function updateDeviceToEdit() {
    if (networkdata) {
      for (let i = 0; i < networkdata.devices.length; i++) {
        if (
          deviceToEdit.id.wg_public_key ===
          networkdata.devices[i].id.wg_public_key
        ) {
          setDeviceToEdit(networkdata.devices[i]);
        }
      }
    }
  }
  function updateCheckedList() {
    if (networkdata) {
      const devices: DeviceEntryReturn[] = [];
      for (let i = 0; i < checkedList.length; i++) {
        for (let j = 0; j < networkdata.devices.length; j++) {
          if (
            checkedList[i].id.wg_public_key ===
            networkdata.devices[j].id.wg_public_key
          ) {
            devices.push(networkdata.devices[j]);
          }
        }
      }
      setCheckedList(devices);
    }
  }
  function addToChecked(device: DeviceEntryReturn) {
    // called onChange of the check box on router cards
    // if router is already in the list and this is called, that means deselect it(take it out of list)
    // check each router in checked list... if the new device arg's id matches any of the ones in checked then remove
    const selected = [...checkedList];
    for (let i = 0; i < selected.length; i++) {
      if (
        device.id.eth_address === selected[i].id.eth_address &&
        device.id.mesh_ip === selected[i].id.mesh_ip &&
        device.id.wg_public_key === selected[i].id.wg_public_key
      ) {
        // device being unchecked
        selected.splice(i, 1);
        setCheckedList(selected);
        return;
      }
    }
    selected.push(device);
    setCheckedList(selected);
  }
  function setOnOffSelection(
    selectState: boolean,
    devicesInView: DeviceEntryReturn[]
  ) {
    const selected = [];
    if (
      networkdata === null ||
      networkdata.devices === null ||
      networkdata.devices.length === 0
    ) {
      return;
    }

    for (let i = 0; i < devicesInView.length; i++) {
      if (!selectState) {
        selected[i] = devicesInView[i];
      }
    }
    setCheckedList(selected);
  }

  function display_wifi_buttons(
    dev: DeviceEntryReturn,
    props: OperatorActionProps,
    multi: boolean,
    device_ids: Identity[]
  ) {
    const semvar = dev.version.split(".");
    const minor_version = parseInt(semvar[1]);
    const patch_version = parseInt(semvar[2]);

    const new_wifi = [];
    new_wifi.push(
      <>
        <Button color="warning" onClick={() => props.set_wifi_actions_toggle()}>
          Set Wifi Info
        </Button>{" "}
      </>
    );

    if (minor_version >= 21) {
      return new_wifi;
    }

    if (minor_version === 20 && patch_version > 10) {
      return new_wifi;
    }

    return (
      <>
        <Button
          color="warning"
          onClick={() =>
            props.submit_operator_action(device_ids, "ResetWiFiPassword", multi)
          }
        >
          Reset WiFi Password
        </Button>{" "}
      </>
    );
  }

  function operatorActionButtons(props: OperatorActionProps, multi: boolean) {
    let pending;
    const device1 = props.devices_to_view[0];
    if (device1.pending_operator_action) {
      let stringified = JSON.stringify(device1.pending_operator_action);
      if (typeof device1.pending_operator_action !== "string") {
        if (stringified.includes("Sysupgrade")) {
          stringified = "Update via Sysupgrade";
        } else if (stringified.includes("Opkg")) {
          stringified = "Update via Opkg";
        } else if (stringified.includes("ChangeOperatorAddress")) {
          stringified = "Change Operator Address";
        } else if (stringified.includes("UpdateAuthorizedKeys")) {
          stringified = "Update Authorized Keys";
        } else if (stringified.includes("Wifi")) {
          stringified = "Set Wifi Info";
        }
      }
      pending = (
        <>
          <p>Pending action: {stringified}</p>
        </>
      );
    }
    const device_ids: Identity[] = [];
    for (const device of props.devices_to_view) {
      device_ids.push(device.id);
    }

    let latest_update;
    let stable_update;
    if (device1.extra_data?.type === "TowerInfo") {
      latest_update =
        "Update to Latest (" + device1.update_paths.lte_latest + ")";
      stable_update =
        "Update to Stable (" + device1.update_paths.lte_stable + ")";
    } else {
      latest_update = "Update to Latest (" + device1.update_paths.latest + ")";
      stable_update = "Update to Stable (" + device1.update_paths.stable + ")";
    }

    let disable_softreboot = false;
    const semvar = device1.version.split(".");
    const minor_version = parseInt(semvar[1]);
    const patch_version = parseInt(semvar[2]);
    if (minor_version < 20) {
      disable_softreboot = true;
    }
    if (minor_version == 20 && patch_version < 20) {
      disable_softreboot = true;
    }

    return (
      <>
        {pending}
        <Button
          color="primary"
          onClick={() =>
            props.submit_operator_action(device_ids, "Reboot", multi)
          }
        >
          Reboot Router
        </Button>
        <Button
          color="primary"
          disabled={disable_softreboot}
          onClick={() =>
            props.submit_operator_action(device_ids, "SoftReboot", multi)
          }
        >
          Soft Reboot
        </Button>
        <Button
          color="primary"
          onClick={() =>
            props.submit_operator_action(device_ids, "ResetShaper", multi)
          }
        >
          Reset Traffic Shaper
        </Button>
        {display_wifi_buttons(device1, props, multi, device_ids)}
        <Button
          color="warning"
          onClick={() =>
            props.submit_operator_action(
              device_ids,
              "ResetRouterPassword",
              multi
            )
          }
        >
          Reset Dashboard Password
        </Button>
        <Button
          color="warning"
          onClick={() =>
            props.submit_operator_action(device_ids, "updateStable", multi)
          }
        >
          {stable_update}
        </Button>
        <Button
          color="warning"
          onClick={() =>
            props.submit_operator_action(device_ids, "updateLatest", multi)
          }
        >
          {latest_update}
        </Button>
        <Button
          color="warning"
          onClick={() =>
            props.submit_operator_action(device_ids, "opkginstall", multi)
          }
        >
          Opkg Install Only
        </Button>{" "}
        <InputGroup>
          <Input
            id="NewOperatorAddress"
            value={props.new_operator_address}
            onChange={(e) => props.set_new_operator_address(e.target.value)}
          />
          <InputGroupAddon addonType="append">
            <Button
              color="warning"
              onClick={() =>
                props.submit_device_network(
                  device_ids,
                  props.new_operator_address,
                  multi
                )
              }
            >
              Change Operator Address
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </>
    );
  }
  function superUserActionButtons(
    props: SuperUserOperatorActionProps,
    multi: boolean
  ) {
    const device_ids: Identity[] = [];
    for (const device of props.device_to_view) {
      device_ids.push(device.id);
    }
    return (
      <>
        <Button
          color="warning"
          onClick={() =>
            props.submit_operator_action(device_ids, "updateSys", multi)
          }
        >
          Update via Sysupgrade
        </Button>{" "}
        <InputGroup>
          <Input
            id="AddAuthKey"
            value={props.update_auth_keys}
            onChange={(e) => props.set_update_auth_keys(e.target.value)}
          ></Input>
          <Button
            color="warning"
            onClick={() =>
              props.submit_operator_action(
                device_ids,
                `addSsh_${props.update_auth_keys}`,
                multi
              )
            }
          >
            Add ssh key using input field
          </Button>{" "}
        </InputGroup>
      </>
    );
  }
  // these actions are for readwrite and up users
  function secondaryActionButtons(props: SecondaryActionProps, multi: boolean) {
    const device_ids: Identity[] = [];
    for (const device of props.device_to_view) {
      device_ids.push(device.id);
    }
    return (
      <>
        <InputGroup>
          <Input
            id="SetTextNotif"
            value={props.new_text}
            maxLength={480}
            onChange={(e) => props.set_new_text(e.target.value)}
          />
          <InputGroupAddon addonType="append">
            <Button
              color="warning"
              onClick={() =>
                props.submit_secondary_action(
                  device_ids,
                  `sendText`,
                  props.new_text,
                  multi
                )
              }
            >
              Send Text Notification
            </Button>{" "}
          </InputGroupAddon>
        </InputGroup>
      </>
    );
  }
  async function deleteDevice(device_to_delete: any) {
    const postJson: any = device_to_delete;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "device_delete", requestOptions);
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  async function deleteNetwork(operator_address: string) {
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    const res = await fetch(
      SERVER_URL + "network_delete/" + operator_address,
      requestOptions
    );
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetworks();
    return res;
  }

  // this will get the currently viewed network if we have one set
  async function getNetwork() {
    if (!operator_address || operator_address === "devices") {
      clearTimeout(timeoutId);
      return;
    }
    let request_url;
    if (operator_address === "unattached") {
      request_url = SERVER_URL + "unattached_devices";
    } else {
      request_url = SERVER_URL + "network/" + operator_address;
    }

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(deviceSortString.current.toLowerCase()),
      credentials: "include",
      signal,
    };
    getStatus();
    const result = await fetch(request_url, requestOptions);
    const return_code = result.status;
    clearTimeout(timeoutId);

    if (return_code === 401) {
      await setLoggedIn(false);
    } else if (return_code === 200) {
      await setLoggedIn(true);
    }

    const json = await result.json();
    setNetworkData(json);
  }
  // used from the all devices page
  async function searchAllDevices(query: string, key: string) {
    const postJson: any = {};
    postJson["query"] = query;
    postJson["key"] = key;
    postJson["sort"] = deviceSortString.current.toLowerCase();
    const requestOptions: any = {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      signal,
    };
    const result = await fetch(
      SERVER_URL + "search_all_devices",
      requestOptions
    );
    clearTimeout(timeoutId);
    if (result.status === 200) {
      const json = await result.json();
      setNetworkData(json);
      return json;
    }
  }
  // this will get the router status mapping from the backend and will not attempt to call more than once
  async function getStatus() {
    if (firstTime.current !== 0) {
      return;
    }
    const requestOptions: any = {
      method: "GET",
      credentials: "include",
    };
    const request_url = SERVER_URL + "router_status_metadata";

    const result = await fetch(request_url, requestOptions);

    const json = await result.json();
    setStatusMaps(json);
  }
  function setStatusMaps(json: any) {
    const status_map_converted: Status[] = [];
    const flagged_status_map: boolean[] = [];
    for (let index = 0; index < json.length; index++) {
      const tuple: [string, string] = json[index];
      const status_var: string = tuple[0];
      const new_status: Status = {
        status: status_var,
        color: tuple[1],
      };
      status_map_converted[index] = new_status;
      flagged_status_map[index] = true;
    }
    setRouterStatus(status_map_converted);
    setFlaggedRouterStatus(flagged_status_map);
  }

  // this will get a list of networks that the user is
  // allowed to view, if the request fails because we are unauthorized
  // that means we have to login
  async function getNetworks() {
    const requestOptions: any = {
      method: "GET",
      credentials: "include",
      signal,
    };
    const result = await fetch(SERVER_URL + "networks", requestOptions);
    const return_code = result.status;
    // Handle successful response
    clearTimeout(timeoutId);
    if (return_code === 401) {
      await setLoggedIn(false);
    } else if (return_code === 200) {
      await setLoggedIn(true);
      const json = await result.json();
      setNetworksList(json);

      // now that we are logged in we should figure out what our permissions are
      const permissions_request = await fetch(
        SERVER_URL + "permissions",
        requestOptions
      );
      const perms_json = await permissions_request.json();
      setPermissions(perms_json);
      // now that we are logged in we should figure out what our peer users list is
      const users_request = await fetch(SERVER_URL + "users", requestOptions);
      const users_json = await users_request.json();
      setUsersList(users_json);

      // if we are a super user we have special endpoints to hit
      if (we_are_superuser(perms_json)) {
        const requestOptions: any = {
          method: "GET",
          credentials: "include",
        };
        const result = await fetch(
          SERVER_URL + "unattached_summary",
          requestOptions
        );
        if (result.status === 200) {
          setUnattachedRouterSummary(await result.json());
        }
      }
    }
  }

  // this will get the operator finances for the currently viewed network
  async function getOperatorFinances() {
    if (!operator_address) {
      return;
    }
    let request_url;
    if (operator_address === "unattached" || operator_address === "devices") {
      return;
    } else {
      request_url = SERVER_URL + "network_finances";
    }

    const postJson: any = {};
    postJson["address"] = operator_address;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };

    const result = await fetch(request_url, requestOptions);
    const json = await result.json();
    json["date"] = new Date();
    setOperatorFinances(json);
  }

  async function submitDeviceNetwork(
    id: [Identity],
    network: string,
    multi: boolean
  ) {
    const postJson: any = {};
    postJson["id"] = id;
    postJson["network"] = network;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "device_network", requestOptions);
    await getNetwork();
    if (multi) {
      //multiple routers selected
      updateCheckedList();
    } else {
      updateDeviceToEdit();
    }
    return res;
  }

  async function submitAction(id: [Identity], action: string, multi: boolean) {
    const postJson: any = {};
    postJson["id"] = id;
    postJson["action"] = action;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "device_action", requestOptions);
    if (res !== null && res.ok) {
      setPopupText("Action submitted successfully");
      openSuccessSB();
    } else {
      setPopupText(await res.json());
      openErrorSB();
    }
    await getNetwork();
    if (multi) {
      //multiple routers selected
      updateCheckedList();
    } else {
      updateDeviceToEdit();
    }
    return res;
  }

  async function submitSecondaryAction(
    id: [Identity],
    action: string,
    extra: string,
    multi: boolean
  ) {
    const postJson: any = {};
    postJson["id"] = id;
    postJson["action"] = action;
    postJson["extra"] = extra;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(
      SERVER_URL + "device_secondary_action",
      requestOptions
    );
    if (res !== null && res.ok) {
      setPopupText("Action submitted successfully");
      openSuccessSB();
    } else {
      setPopupText(await res.json());
      openErrorSB();
    }
    await getNetwork();
    if (multi) {
      //multiple routers selected
      updateCheckedList();
    } else {
      updateDeviceToEdit();
    }
    return res;
  }

  async function submitExitAction(device_id: Identity, action: string) {
    const postJson: any = {};
    postJson["device_id"] = device_id;
    postJson["action"] = action;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "exit_action", requestOptions);
    await getNetwork();
    return res;
  }

  async function submitName(name: string, id: Identity) {
    const postJson: any = {};
    postJson["id"] = id;
    postJson["name"] = name;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "device_name", requestOptions);
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  // Compudopt only splynx id valute, each number represents a splynx user
  async function submitSplynxId(splynx_id: number | null, id: Identity) {
    // zero is invalid
    if (splynx_id && splynx_id == 0) {
      splynx_id = null;
    }
    const postJson: any = {};
    postJson["id"] = id;
    postJson["splynx_id"] = splynx_id;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "device_splynx_id", requestOptions);
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  async function submitNetworkName(name: string, address: string) {
    const postJson: any = {};
    postJson["address"] = address;
    postJson["name"] = name;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "network_name", requestOptions);
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetworks();
    return res;
  }
  async function submitComments(comments: string, id: Identity) {
    console.log("submitting comments");
    console.log(comments);
    const postJson: any = {};
    postJson["id"] = id;
    postJson["comments"] = comments;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "device_comments", requestOptions);
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  async function submitInstallDetails(
    client_antenna_ip: string,
    relay_antennas: string,
    phys_address: string,
    equipment_details: string,
    id: Identity
  ) {
    console.log("submitting install details");
    const postJson: any = {};
    postJson["id"] = id;
    postJson["client_antenna_ip"] = client_antenna_ip;
    postJson["relay_antennas"] = relay_antennas;
    postJson["phys_address"] = phys_address;
    postJson["equipment_details"] = equipment_details;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };

    const res = await fetch(
      SERVER_URL + "device_installdetails",
      requestOptions
    );
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  async function submitContactDetails(
    phone: string | null,
    email: string | null,
    user_id: number,
    set_acp: boolean
  ) {
    const postJson: any = {};
    if (phone && !phone.startsWith("+")) {
      phone = "+1" + phone;
    }
    postJson["id"] = user_id;
    postJson["phone"] = phone;
    postJson["email"] = email;
    postJson["acp"] = set_acp;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "device_contacts", requestOptions);
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  async function submitBillingDetails(
    id: Identity,
    user_first_name: string,
    user_last_name: string,
    country: string,
    postal_code: string,
    state: string | null,
    city: string,
    street: string
  ) {
    console.log("submitting billing details");
    const postJson: any = {};
    postJson["id"] = id;
    postJson["user_first_name"] = user_first_name;
    postJson["user_last_name"] = user_last_name;
    postJson["country"] = country;
    postJson["postal_code"] = postal_code;
    postJson["state"] = state;
    postJson["city"] = city;
    postJson["street"] = street;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };

    const res = await fetch(
      SERVER_URL + "device_billingdetails",
      requestOptions
    );
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  async function getEPCAddrList() {
    if (!operator_address) {
      return;
    }
    const postJson: any = operator_address;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "get_epc_list", requestOptions);
    if (res.ok) {
      const epc_json = await res.json();
      setEpcList(epc_json);
    }
  }
  async function submitEPCAddr(new_epc_addr: string, id: Identity) {
    const postJson: any = {};
    postJson["id"] = id;
    postJson["new_epc_addr"] = new_epc_addr;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "device_epc_addr", requestOptions);
    await getNetwork();
    return res;
  }
  async function submitNetworkNotes(notes: string, address: string) {
    console.log("submitting Network notes");
    const postJson: any = {};
    postJson["address"] = address;
    postJson["notes"] = notes;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const res = await fetch(SERVER_URL + "network_notes", requestOptions);
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  async function submitForward(
    ip: IpAddress.Address4,
    port: number,
    id: Identity
  ) {
    const postJson: any = {};
    postJson["id"] = id;
    postJson["ip"] = ip;
    postJson["port"] = port;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const res = await fetch(SERVER_URL + "forward/start", requestOptions);
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  async function haltForward(id: any) {
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(id),
    };
    const res = await fetch(SERVER_URL + "forward/stop", requestOptions);
    // force update these vars after submitting name so that the effect
    // takes place immediately from the user perspective
    await getNetwork();
    return res;
  }
  async function login(username: string, password: string) {
    const postJson: any = {};
    postJson["username"] = username;
    postJson["password"] = password;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const res = await fetch(SERVER_URL + "login", requestOptions);
    if (res.status === 403) {
      setLastLoginFailed(true);
    } else {
      setLastLoginFailed(false);
      setLoggedIn(true);
    }
    // try getting networks again which will update the login var and
    // display the info to us
    await getNetwork();
    await getNetworks();
    return res;
  }

  async function get_code(phone: string) {
    const postJson: SMSMessage = {
      phone: phone,
    };
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    setPhone(phone);

    const res = await fetch(SERVER_URL + "signup/verify", requestOptions);
    return res;
  }

  async function signup(
    username: string,
    password: string,
    phone: string,
    code: string
  ) {
    const postJson: any = {};
    postJson["username"] = username;
    postJson["password"] = password;
    postJson["phone"] = phone;
    postJson["code"] = code;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const res = await fetch(SERVER_URL + "signup/create", requestOptions);
    return res.status;
  }

  async function get_alerts() {
    if (operator_address === "devices") {
      return;
    }
    const requestOptions: any = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    const res = await fetch(
      SERVER_URL + "alerts/" + operator_address,
      requestOptions
    );
    if (res.ok) {
      setAlerts(await res.json());
    }
  }
  async function dismiss_alert(id: number) {
    const postJson: any = {};
    postJson["address"] = operator_address;
    postJson["id"] = id;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const res = await fetch(SERVER_URL + "network_dismiss", requestOptions);
    await get_alerts();
    return res;
  }
  async function dismiss_all_alerts() {
    const postJson: any = {};
    postJson["address"] = operator_address;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const res = await fetch(SERVER_URL + "network_dismiss_all", requestOptions);
    await get_alerts();
    return res;
  }
  async function add_user(
    // the new username to be checked for uniqueness
    new_username: string,
    // the new password in plaintext, it will be hashed and slated on the backend
    new_password: string,
    // a single network to start off with permissions on
    network: string,
    // the permissions level for the added network
    permission_level: string
  ) {
    const postJson: any = {};
    const perms_json: any = {};
    perms_json[permission_level] = {};
    perms_json[permission_level]["network"] = network;
    postJson["username"] = new_username;
    postJson["password"] = new_password;
    postJson["requested_permission"] = perms_json;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    await fetch(SERVER_URL + "user/create", requestOptions);
    await update_users_list();
  }
  async function add_permission(
    // the existing user to change
    username: string,
    // a single network to add/upgrade permissions
    network: string,
    // the permissions level for the network
    permission_level: string
  ) {
    const postJson: any = {};
    const perms_json: any = {};
    perms_json[permission_level] = {};
    perms_json[permission_level]["network"] = network;
    postJson["username"] = username;
    postJson["requested_added_permission"] = perms_json;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    await fetch(SERVER_URL + "user/add", requestOptions);
    await update_users_list();
  }
  async function remove_permission(
    // the existing user to change
    username: string,
    // a single network to remove permissions
    network: string
  ) {
    const postJson: any = {};
    postJson["username"] = username;
    postJson["requested_removed_network"] = network;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    await fetch(SERVER_URL + "user/remove", requestOptions);
    await update_users_list();
  }
  const [lastPasswordChangeFailed, setLastPasswordChangeFailed] =
    useState<boolean>(false);
  async function change_password(old_password: string, new_password: string) {
    const postJson: any = {};
    postJson["old_password"] = old_password;
    postJson["new_password"] = new_password;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const res = await fetch(SERVER_URL + "password/change", requestOptions);
    if (res.status === 401) {
      setLastPasswordChangeFailed(true);
    } else {
      setLastPasswordChangeFailed(false);
    }
  }
  async function reset_password(username: string, new_password: string) {
    const postJson: any = {};
    postJson["username"] = username;
    postJson["new_password"] = new_password;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    await fetch(SERVER_URL + "password/reset", requestOptions);
    await update_users_list();
  }
  async function update_users_list() {
    const requestOptions: any = {
      method: "GET",
      credentials: "include",
    };
    const users_request = await fetch(SERVER_URL + "users", requestOptions);
    const users_json = await users_request.json();
    setUsersList(users_json);
  }
  async function get_user_finances(device_to_edit: any) {
    if (!device_to_edit) {
      return;
    }

    const postJson: any = {};
    postJson["id"] = device_to_edit.id;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const finances_request = await fetch(
      SERVER_URL + "device_finances",
      requestOptions
    );
    const finances_request_json = await finances_request.json();
    setUserFinances(finances_request_json);
  }

  async function get_user_latencies(
    device_to_edit: any,
    toggled_number_of_days: any
  ) {
    if (!device_to_edit) {
      return;
    }

    const postJson: any = {};
    postJson["id"] = device_to_edit.id;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };
    const latencies_request = await fetch(
      SERVER_URL + "device_latencies/" + toggled_number_of_days,
      requestOptions
    );
    if (latencies_request.status === 400) {
      // keyLTE devices do not have latency data
      setUserLatencies(null);
    } else if (latencies_request.status === 200) {
      const latencies_request_json = await latencies_request.json();
      setUserLatencies(latencies_request_json);
    }
    setToggledDays(toggled_number_of_days);
  }

  async function get_device_usage(
    device_to_edit: any,
    toggled_number_of_days: any
  ) {
    if (!device_to_edit) {
      return;
    }

    const postJson: any = {};
    postJson["id"] = device_to_edit.id;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
      credentials: "include",
    };

    const usage_request = await fetch(
      SERVER_URL + "device_usage/" + toggled_number_of_days,
      requestOptions
    );
    // if we get a 500 error here it's because we do not have any saved usage data
    // for this device and cannot display it.
    if (usage_request.status == 500) {
      setUserBandwidthUsage(null);
    } else {
      const usage_request_json = await usage_request.json();
      setUserBandwidthUsage(usage_request_json);
    }
    setToggledDays(toggled_number_of_days);
  }

  // this will get the network's active user history
  async function getNetworkUserActivity(operator_address: string) {
    if (!operator_address) {
      return;
    }
    let request_url;
    // this must be for assigned address networks only
    if (operator_address === "unattached") {
      return;
    } else if (operator_address === "devices") {
      return;
    } else {
      request_url =
        SERVER_URL + "network_active_user_history/" + operator_address;
    }
    const requestOptions: any = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    const res = await fetch(request_url, requestOptions);
    const json = await res.json();
    setNetworkUserActivity(json);
  }

  // gets the average usage over an entire network
  async function get_network_usage(operator_address: string) {
    let request_url;
    if (operator_address === "unattached") {
      request_url = SERVER_URL + "network_usage/" + "unattached_devices";
    } else if (operator_address === "devices") {
      request_url = SERVER_URL + "network_usage/" + "all_devices";
    } else {
      request_url = SERVER_URL + "network_usage/" + operator_address;
    }

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    getStatus();
    const result = await fetch(request_url, requestOptions);
    const return_code = await result.status;

    // if we get a 500 error here it's because we do not have any saved usage data
    // for this network and cannot display it.
    if (return_code === 500) {
      setNetworkBandwidthUsage([]);
    } else if (return_code === 200) {
      const json = await result.json();
      setNetworkBandwidthUsage(json);
    }
  }

  // gets the customer details for a specific device
  async function get_customer(device_to_edit: any) {
    const postJson: any = {};
    postJson["device"] = device_to_edit.id;
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const result = await fetch(SERVER_URL + "get_customer", requestOptions);
    const return_code = await result.status;

    // if we get a 500 error here it's because we do not have any saved user data
    // for this ID and cannot display it.
    if (return_code === 500) {
      setCustomerToEdit(null);
    } else if (return_code === 200) {
      const json = await result.json();
      setCustomerToEdit(json);
    }
  }

  async function submit_customer(
    device_to_edit: any,
    user_id: number,
    nickname: string,
    notes: string,
    new_assignment: boolean
  ) {
    const postJson: any = {};
    postJson["user_id"] = user_id;
    postJson["router_id"] = device_to_edit.id;
    postJson["nickname"] = nickname;
    postJson["notes"] = notes;
    postJson["new_assignment"] = new_assignment;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const _result = await fetch(SERVER_URL + "set_customer", requestOptions);
    setDeviceToEdit(device_to_edit);
    get_customer(device_to_edit);
  }

  async function editCustomer(
    user_id: number,
    nickname: string,
    notes: string
  ) {
    const postJson: any = {};
    postJson["user_id"] = user_id;
    postJson["nickname"] = nickname;
    postJson["notes"] = notes;

    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const _result = await fetch(SERVER_URL + "save_customer", requestOptions);
    await get_all_customers();
  }

  // gets the customer details for all customer entries saved
  async function get_all_customers() {
    const postJson: any = {};
    const requestOptions: any = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(postJson),
    };
    const result = await fetch(
      SERVER_URL + "get_all_customers",
      requestOptions
    );
    const return_code = result.status;
    // if we get a 401 error here it's because this user is not authorized
    if (return_code === 401) {
      setCustomerList([]);
    } else if (return_code === 200) {
      const json = await result.json();
      setCustomerList(json);
      console.log("data is ", json);

      return json;
    }
  }

  useEffect(() => {
    getNetwork();
    const interval = setInterval(() => {
      getNetwork();
      firstTime.current = firstTime.current + 1;
    }, UPDATE_TIME);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    getEPCAddrList();
    get_alerts();
    getNetworks();
    slow_interval_loops.current = slow_interval_loops.current + 1;
    const slowinterval = setInterval(() => {
      getEPCAddrList();
      get_alerts();
      getNetworks();
      slow_interval_loops.current = slow_interval_loops.current + 1;
    }, FIVE_MINUTES);
    return () => clearInterval(slowinterval);
  }, []);

  let alert_count = 0;
  if (alerts) {
    alert_count = alerts.length;
  }

  if (!loggedIn) {
    console.log("not logged in, returning loginpage");
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route
            path="/authentication"
            element={<SignIn login={login} lastLoginFailed={lastLoginFailed} />}
          />
          <Route
            path="/authentication/signup/verify"
            element={<SignUp get_code={get_code} />}
          />
          <Route
            path="/authentication/signup/create"
            element={<Verify phone={phone} signup={signup} />}
          ></Route>
          <Route path="/authentication/verified" element={<Confirm />} />
          <Route path="*" element={<Navigate to="/authentication" />} />
        </Routes>
      </ThemeProvider>
    );
  }

  if (operator_address) {
    if (!local_page || local_page.toLowerCase() === "monitor") {
      return (
        <ThemeProvider theme={theme}>
          {layout === "vr"}
          <DashboardLayout>
            <DashboardNavbar />
            <SidenavNetwork
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_network(operator_address)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              alert_count={alert_count}
              trigger_overview_modal={triggerOverviewModal}
              network={networkdata}
            />
            <OverviewModal
              network={networkdata}
              usage_data={networkBandwidthUsage}
              is_open={overviewModal}
              toggle={toggleOverviewModal}
              operator_finances={operatorFinances}
              activity_history={networkUserActivity}
            />
            <Monitor
              network_data={networkdata}
              checked_list={checkedList}
              trigger_comments_modal={triggerCommentsModal}
              trigger_forward_modal={triggerForwardModal}
              trigger_router_details_modal={triggerRouterDetailsModal}
              trigger_contact_details_modal={triggerContactDetailsModal}
              trigger_finance_details_modal={triggerFinanceDetailsModal}
              trigger_network_latencies_modal={triggerNetworkLatenciesModal}
              trigger_topology_modal={triggerTopologyModal}
              trigger_multiple_routers_modal={triggerMultipleRoutersModal}
              trigger_router_statuses_modal={triggerRouterStatusesModal}
              trigger_filter_modal={triggerFilterModal}
              halt_forward={haltForward}
              delete_device={deleteDevice}
              add_to_checked={addToChecked}
              trigger_monitor_sort={triggerMonitorSort}
              sort_devices_string={deviceSortString.current}
              status_map={routerStatus}
              flagged_router_status={flaggedRouterStatus}
              toggle_filter_modal={toggleFiltermodal}
              set_on_off_selection={setOnOffSelection}
              get_statuses={getStatus}
            />
            <RouterStatusesModal
              status_map={routerStatus}
              flagged_status={routerStatuses}
              is_open={routerStatusesModal}
              toggle={toggleRouterStatusesModal}
              name={routerName}
            />
            <FilterModal
              status_map={routerStatus}
              flagged_status={flaggedRouterStatus}
              is_open={filterModal}
              toggle={toggleFiltermodal}
              setFlaggedRouterStatus={setFlaggedRouterStatus}
            />
            <NameModal
              device_to_edit={deviceToEdit}
              is_open={nameModal}
              toggle={toggleNameModal}
              submit={submitName}
            />
            <CommentsModal
              device_to_edit={deviceToEdit}
              is_open={commentsModal}
              toggle={toggleCommentsModal}
              submit_name={submitName}
              submit_comments={submitComments}
              submit_install_details={submitInstallDetails}
              delete_device={deleteDevice}
              submit_splynx_id={submitSplynxId}
            />
            <ForwardModal
              device_to_edit={deviceToEdit}
              is_open={forwardModal}
              toggle={toggleForwardModal}
              submit={submitForward}
            />
            <RouterDetailsModal
              device_to_view={deviceToEdit}
              is_open={routerDetailsModal}
              our_permissions={permissions}
              set_wifi_actions_modal={wifiActionsModal}
              epc_list={epcList}
              submit_epc_addr={submitEPCAddr}
              toggle={toggleRouterDetailsModal}
              submit_operator_action={submitAction}
              submit_device_network={submitDeviceNetwork}
              operator_action_buttons={operatorActionButtons}
              super_user_action_buttons={superUserActionButtons}
              toggle_set_wifi_actions={toggleSetWifiActionsModal}
              error={renderErrorSB}
              success={renderSuccessSB}
            />
            <MultipleRoutersModal
              devices_to_view={checkedList}
              address={operator_address}
              is_open={multipleRoutersModal}
              our_permissions={permissions}
              set_wifi_actions_modal={wifiActionsModal}
              toggle={toggleMultipleRoutersModal}
              submit_operator_action={submitAction}
              submit_device_network={submitDeviceNetwork}
              operator_action_buttons={operatorActionButtons}
              secondary_action_buttons={secondaryActionButtons}
              submit_secondary_action={submitSecondaryAction}
              super_user_action_buttons={superUserActionButtons}
              toggle_set_wifi_actions={toggleSetWifiActionsModal}
              error={renderErrorSB}
              success={renderSuccessSB}
            />
            {contactDetailsModal ? (
              <ContactDetailsModal
                device_to_view={deviceToEdit}
                is_open={contactDetailsModal}
                toggle={toggleContactDetailsModal}
                submit_contacts={submitContactDetails}
                submit_billing={submitBillingDetails}
                toggle_customer_info_modal={toggleCustomerInfoModal}
                set_customer_info_modal={customerInfoModal}
                customer_to_edit={customerToEdit}
                submit_customer={submit_customer}
                our_permissions={permissions}
                network={networkdata}
              />
            ) : (
              ""
            )}
            <FinanceModal
              device_to_view={deviceToEdit}
              finances={userFinances}
              is_open={financeModal}
              toggle={toggleFinanceModal}
            />
            <TopologyModal
              all_devices={networkdata}
              device_to_view={deviceToEdit}
              is_open={topologyModal}
              toggle={toggleTopologyModal}
              submit_exit_action={submitExitAction}
            />
            <LatenciesModal
              device_to_view={deviceToEdit}
              network={networkdata}
              latencies={userLatencies}
              usage_data={userBandwidthUsage}
              is_open={networkLatencyModal}
              toggle={toggleNetworkLatencyModal}
              toggled_number_of_days={toggledNumberOfDays}
            />
          </DashboardLayout>
        </ThemeProvider>
      );
    } else if (local_page && local_page.toLowerCase() === "search") {
      return (
        <ThemeProvider theme={theme}>
          {layout === "vr"}
          <DashboardLayout>
            <DashboardNavbar />
            <SidenavNetwork
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_network(operator_address)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              alert_count={alert_count}
              trigger_overview_modal={triggerOverviewModal}
              network={networkdata}
            />
            <OverviewModal
              network={networkdata}
              usage_data={networkBandwidthUsage}
              is_open={overviewModal}
              toggle={toggleOverviewModal}
              operator_finances={operatorFinances}
              activity_history={networkUserActivity}
            />

            <SearchMonitor
              network_data={networkdata}
              checked_list={checkedList}
              trigger_comments_modal={triggerCommentsModal}
              trigger_forward_modal={triggerForwardModal}
              trigger_router_details_modal={triggerRouterDetailsModal}
              trigger_contact_details_modal={triggerContactDetailsModal}
              trigger_finance_details_modal={triggerFinanceDetailsModal}
              trigger_network_latencies_modal={triggerNetworkLatenciesModal}
              trigger_topology_modal={triggerTopologyModal}
              trigger_multiple_routers_modal={triggerMultipleRoutersModal}
              trigger_router_statuses_modal={triggerRouterStatusesModal}
              halt_forward={haltForward}
              delete_device={deleteDevice}
              add_to_checked={addToChecked}
              trigger_monitor_sort={triggerMonitorSort}
              sort_devices_string={deviceSortString.current}
              status_map={routerStatus}
              flagged_router_status={flaggedRouterStatus}
              set_on_off_selection={setOnOffSelection}
              get_statuses={getStatus}
              search_all_devices={searchAllDevices}
            />
            <RouterStatusesModal
              status_map={routerStatus}
              flagged_status={routerStatuses}
              is_open={routerStatusesModal}
              toggle={toggleRouterStatusesModal}
              name={routerName}
            />
            <FilterModal
              status_map={routerStatus}
              flagged_status={flaggedRouterStatus}
              is_open={filterModal}
              toggle={toggleFiltermodal}
              setFlaggedRouterStatus={setFlaggedRouterStatus}
            />
            <NameModal
              device_to_edit={deviceToEdit}
              is_open={nameModal}
              toggle={toggleNameModal}
              submit={submitName}
            />
            <CommentsModal
              device_to_edit={deviceToEdit}
              is_open={commentsModal}
              toggle={toggleCommentsModal}
              submit_name={submitName}
              submit_comments={submitComments}
              submit_install_details={submitInstallDetails}
              delete_device={deleteDevice}
              submit_splynx_id={submitSplynxId}
            />
            <ForwardModal
              device_to_edit={deviceToEdit}
              is_open={forwardModal}
              toggle={toggleForwardModal}
              submit={submitForward}
            />
            <RouterDetailsModal
              device_to_view={deviceToEdit}
              is_open={routerDetailsModal}
              our_permissions={permissions}
              set_wifi_actions_modal={wifiActionsModal}
              epc_list={epcList}
              submit_epc_addr={submitEPCAddr}
              toggle={toggleRouterDetailsModal}
              submit_operator_action={submitAction}
              submit_device_network={submitDeviceNetwork}
              operator_action_buttons={operatorActionButtons}
              super_user_action_buttons={superUserActionButtons}
              toggle_set_wifi_actions={toggleSetWifiActionsModal}
              error={renderErrorSB}
              success={renderSuccessSB}
            />
            <MultipleRoutersModal
              devices_to_view={checkedList}
              address={operator_address}
              is_open={multipleRoutersModal}
              our_permissions={permissions}
              set_wifi_actions_modal={wifiActionsModal}
              toggle={toggleMultipleRoutersModal}
              submit_operator_action={submitAction}
              submit_device_network={submitDeviceNetwork}
              operator_action_buttons={operatorActionButtons}
              secondary_action_buttons={secondaryActionButtons}
              submit_secondary_action={submitSecondaryAction}
              super_user_action_buttons={superUserActionButtons}
              toggle_set_wifi_actions={toggleSetWifiActionsModal}
              error={renderErrorSB}
              success={renderSuccessSB}
            />
            {contactDetailsModal ? (
              <ContactDetailsModal
                device_to_view={deviceToEdit}
                is_open={contactDetailsModal}
                toggle={toggleContactDetailsModal}
                submit_contacts={submitContactDetails}
                submit_billing={submitBillingDetails}
                toggle_customer_info_modal={toggleCustomerInfoModal}
                set_customer_info_modal={customerInfoModal}
                customer_to_edit={customerToEdit}
                submit_customer={submit_customer}
                our_permissions={permissions}
                network={networkdata}
              />
            ) : (
              ""
            )}
            <FinanceModal
              device_to_view={deviceToEdit}
              finances={userFinances}
              is_open={financeModal}
              toggle={toggleFinanceModal}
            />
            <TopologyModal
              all_devices={networkdata}
              device_to_view={deviceToEdit}
              is_open={topologyModal}
              toggle={toggleTopologyModal}
              submit_exit_action={submitExitAction}
            />
            <LatenciesModal
              device_to_view={deviceToEdit}
              network={networkdata}
              latencies={userLatencies}
              usage_data={userBandwidthUsage}
              is_open={networkLatencyModal}
              toggle={toggleNetworkLatencyModal}
              toggled_number_of_days={toggledNumberOfDays}
            />
          </DashboardLayout>
        </ThemeProvider>
      );
    } else if (local_page && local_page.toLowerCase() === "settings") {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <SidenavLanding
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_landing()}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
          {layout === "vr"}
          <DashboardLayout>
            <DashboardNavbar />
            <SidenavNetwork
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_network(operator_address)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              alert_count={alert_count}
              trigger_overview_modal={triggerOverviewModal}
              address={""}
              network={undefined}
            />
            <Settings
              address={operator_address}
              network={networkdata}
              get_network={getNetwork}
            />
          </DashboardLayout>
        </ThemeProvider>
      );
    } else if (local_page && local_page.toLowerCase() === "alerts") {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <SidenavLanding
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_landing()}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
          {layout === "vr"}
          <DashboardLayout>
            <DashboardNavbar />
            <SidenavNetwork
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_network(operator_address)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              alert_count={alert_count}
              trigger_overview_modal={triggerOverviewModal}
              network={networkdata}
            />
            <Alerts
              notification_queue={alerts}
              dismiss_alert={dismiss_alert}
              dismiss_all_alerts={dismiss_all_alerts}
            />
          </DashboardLayout>
        </ThemeProvider>
      );
    } else if (local_page && local_page.toLowerCase() === "notes") {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <SidenavLanding
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_landing()}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
          {layout === "vr"}
          <DashboardLayout>
            <DashboardNavbar />
            <SidenavNetwork
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_network(operator_address)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              alert_count={alert_count}
              trigger_overview_modal={triggerOverviewModal}
              network={networkdata}
            />
            <Notes network_to_edit={networkdata} submit={submitNetworkNotes} />
          </DashboardLayout>
        </ThemeProvider>
      );
    } else if (local_page && local_page.toLowerCase() === "users") {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <SidenavLanding
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_landing()}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
          {layout === "vr"}
          <DashboardLayout>
            <DashboardNavbar />
            <Users
              users_list={usersList}
              networks_list={networksList}
              last_password_change_failed={lastPasswordChangeFailed}
              our_permissions={permissions}
              remove_permission={remove_permission}
              add_user={add_user}
              add_permission={add_permission}
              change_password={change_password}
              reset_password={reset_password}
            />
          </DashboardLayout>
        </ThemeProvider>
      );
    } else if (local_page && local_page.toLowerCase() === "customers") {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <SidenavLanding
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Operator Tools"
              routes={routes_landing()}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
          {layout === "vr"}
          <DashboardLayout>
            <DashboardNavbar />
            <Customers
              customers_list={customerList}
              toggle_customer_modal={toggleCustomerPage}
              customer_modal_is_open={customerPage}
              edit_customer={editCustomer}
              submit_contacts={submitContactDetails}
              get_customer_list={get_all_customers}
            />
          </DashboardLayout>
        </ThemeProvider>
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <SidenavLanding
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName="Operator Tools"
          routes={routes_landing()}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      {layout === "vr"}
      <Routes>
        {getRoutes(routes_landing())}
        <Route
          path="/"
          element={
            <>
              <DashboardLanding
                networks={networksList}
                delete_network={deleteNetwork}
                permissions={permissions}
                unattached_router_summary={unattachedRouterSummary}
                trigger_name_modal={triggerNetworkNameModal}
                trigger_overview_modal={triggerOverviewModal}
              />
              <NameModal
                device_to_edit={networkToEdit}
                is_open={nameModal}
                toggle={toggleNameModal}
                submit={submitNetworkName}
              />
              <OverviewModal
                network={networkdata}
                usage_data={networkBandwidthUsage}
                is_open={overviewModal}
                toggle={toggleOverviewModal}
                operator_finances={operatorFinances}
                activity_history={networkUserActivity}
              />
            </>
          }
        ></Route>
        <Route
          path="/users"
          element={
            <>
              <Users
                users_list={usersList}
                networks_list={networksList}
                last_password_change_failed={lastPasswordChangeFailed}
                our_permissions={permissions}
                remove_permission={remove_permission}
                add_user={add_user}
                add_permission={add_permission}
                change_password={change_password}
                reset_password={reset_password}
              />
            </>
          }
        ></Route>
        <Route
          path="/customers"
          element={
            <>
              <Customers
                customers_list={customerList}
                get_customer_list={get_all_customers}
                toggle_customer_modal={toggleCustomerPage}
                customer_modal_is_open={customerPage}
                edit_customer={editCustomer}
                submit_contacts={submitContactDetails}
              />
            </>
          }
        ></Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
export default App;
